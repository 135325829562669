import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loading from "./Loading";
import LOGO_IDENTITY_HORIZONTAL from "../images/logos/logo-identity-horizontal.png";
import ICON_EMPTY_DOCUMENTS from "../images/logos/icon-empty.png";
import { Layout, Drawer, Header, Navigation, Content } from "react-mdl";
import { Table } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { apiDocuments, apiLogout, apiSearch } from "../constants/api-url";

const Observados = ({ history }) => {
  const [isFetching, setFetching] = useState(true);
  const [documents, setDocuments] = useState(null);
  const VALUEAPIKEY = localStorage.getItem("VALUEAPIKEY_COMPANY");
  const SESSION_ID = localStorage.getItem("SESSION_ID");
  const INSTITUTION = localStorage.getItem("INSTITUTION");
  const USERNAME = localStorage.getItem("USER_NAME");
  const INSTITUTION_IMG = localStorage.getItem("INSTITUTION_IMG");
  const INSTITUTIONS = JSON.parse(localStorage.getItem("INSTITUTIONS"));

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    axios({
      method: "GET",
      url: apiSearch,
      params: {
        institution: INSTITUTION,
        state: 3,
        page: 1,
        session_id: SESSION_ID,
      },
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": VALUEAPIKEY,
      },
    })
      .then(function (response) {
        setFetching(false);
        setDocuments(response.data.result.documents);
      })
      .catch((error) => {
        if (401 === error.response.status) {
          Swal.fire({
            icon: "warning",
            title: "Sesi\u00F3n expirada",
            text: "Su sesi\u00F3n ha caducado. Ser\u00E1 redirigido a la p\u00E1gina de inicio de sesi\u00F3n para logearse nuevamente.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              logoutUser();
            }
          });
        }
      });
  };

  const getDetailDocument = (dataDocument, typeState) => {
    setFetching(true);
    axios({
      method: "GET",
      url: apiDocuments,
      params: {
        code: dataDocument.code,
        institution: dataDocument.institution,
        extra: "signers,preview,folders,comments,qr",
        session_id: SESSION_ID,
      },
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": VALUEAPIKEY,
      },
    })
      .then(function (response) {
        setFetching(false);
        history.push({
          pathname: "detalle-documento",
          state: {
            detailDocument: response.data,
            typeState: typeState,
          },
        });
      })
      .catch((error) => {
        if (401 === error.response.status) {
          Swal.fire({
            icon: "warning",
            title: "Sesi\u00F3n expirada",
            text: "Su sesi\u00F3n ha caducado. Ser\u00E1 redirigido a la p\u00E1gina de inicio de sesi\u00F3n para logearse nuevamente.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: `OK`,
          }).then((result) => {
            if (result.isConfirmed) {
              logoutUser();
            }
          });
        }
      });
  };

  const changeFormatDate = (dateFormat) => {
    const newDate = dateFormat.split(" ")[0];
    const newHour = dateFormat.split(" ")[1];
    var formatDate = newDate.split("-");
    var newDateFormat =
      formatDate[2] + "/" + formatDate[1] + "/" + formatDate[0] + " " + newHour;
    return newDateFormat;
  };

  const logoutUser = () => {
    axios({
      method: "POST",
      url: apiLogout,
      data: {
        session_id: SESSION_ID,
      },
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": VALUEAPIKEY,
      },
    })
      .then(function (response) {
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const table = (
    <Table responsive className="c-table">
      <thead>
        <tr>
          <th>Documento</th>
          <th className="text-center">Fecha de ingreso</th>
        </tr>
      </thead>
      <tbody>
        {(documents || []).map((data) => (
          <tr key={data.code}>
            <td onClick={() => getDetailDocument(data, 3)}>{data.name}</td>
            <td className="text-center">
              {data.date === "" || data.date === null || data.date === undefined
                ? ""
                : changeFormatDate(data.date)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const empty = (
    <div className="c-empty__documents">
      <img src={ICON_EMPTY_DOCUMENTS} alt="Logo Identity" />
      <h2>A&uacute;n no tienes documentos en este estado.</h2>
    </div>
  );

  return (
    <Layout fixedHeader fixedDrawer className="c-layout">
      <Header className="c-header">
        <div className="c-header__title">
          <img src={LOGO_IDENTITY_HORIZONTAL} alt="Logo Identity" />
        </div>
      </Header>
      <Drawer className="c-drawer">
        <div className="c-drawer__image">
          <div className="c-image">
            <img src={INSTITUTION_IMG} alt="Logo Identity" />
          </div>
          <h2>{USERNAME}</h2>
        </div>
        <Navigation>
          <Link
            to={{ pathname: "empresas", state: { institutions: INSTITUTIONS } }}
            className="c-nav__item"
          >
            <div className="c-nav__icon">
              <i className="material-icons">home</i>
            </div>
            <p>Mis empresas</p>
          </Link>
          <NavLink to="firmas" className="c-nav__item">
            <div className="c-nav__icon">
              <i className="material-icons">edit</i>
            </div>
            <p>Doc. por firmar</p>
          </NavLink>
          <NavLink to="proceso-firmas" className="c-nav__item">
            <div className="c-nav__icon">
              <i className="material-icons">rotate_left</i>
            </div>
            <p>Doc. en proceso de firmas</p>
          </NavLink>
          <NavLink to="firmados" className="c-nav__item">
            <div className="c-nav__icon">
              <i className="material-icons">done</i>
            </div>
            <p>Doc. firmados</p>
          </NavLink>
          <NavLink to="observados" className="c-nav__item">
            <div className="c-nav__icon">
              <i className="material-icons">info</i>
            </div>
            <p>Doc. observados</p>
          </NavLink>
          <NavLink to="cambiar-contrasena" className="c-nav__item">
            <div className="c-nav__icon">
              <i className="material-icons">vpn_key</i>
            </div>
            <p>Cambio de contrase&ntilde;a</p>
          </NavLink>
          <a href="#" className="c-nav__item" onClick={() => logoutUser()}>
            <div className="c-nav__icon">
              <i className="material-icons">keyboard_return</i>
            </div>
            <p>Cerrar sesi&oacute;n</p>
          </a>
        </Navigation>
      </Drawer>
      <Content>
        {isFetching ? <Loading></Loading> : null}
        <div className="c-page__title">
          <h2>Mis documentos</h2>
          <i className="material-icons">keyboard_arrow_right</i>
          <p>Observados</p>
        </div>
        <div className="page-content">
          <div className="c-card--container">
            {!isFetching && documents
              ? documents.length > 0
                ? table
                : empty
              : null}
          </div>
        </div>
      </Content>
    </Layout>
  );
};
export default Observados;
