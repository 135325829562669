import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import { db } from '../firebase/firebase-config';
import LOGO from '../images/logos/logo-identity.png';
import FONDO from '../images/fondo/fondo.png';
import SEGURIDAD from '../images/user/security.png';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import '../styles/login.css';

const ValidateInformation = ({ history }) => {

  const styleBackground = {
    backgroundImage:  `url(${FONDO})`,
    backgroundSize: 'cover'
  }

  const styleImageContainer = {
    alignItems: 'center', 
    justifyContent: 'center', 
    display: 'flex'
  }

  const styleImage = {
    width: '40%'
  }

 

  const sendToValidation = () => {
    console.log('regresa al login');
    history.push({
      pathname: 'validacion-usuario',
      state: {
        user_data: history.location.state.user_data_login
      }
    });
  }

  return(
      <div className="c-login" style={styleBackground}>
        <div className="c-login__card">
          <div className="c-card__title">
            <img src={LOGO} alt="Logo Identity" />
            <p>¡¡ Bienvenido !!</p>
          </div>
          <div className="c-card__body">
            <label>Gracias por utilizar nuestro servicio.</label>
            <label>Por su seguridad se proceder&aacute; a validar sus datos personales.</label>
            <br/><br/>
            <div style={styleImageContainer}>
              <img src={SEGURIDAD} alt="Seguridad Informacion" style={styleImage}/>
            </div>
          </div>
          <div className="c-card__actions">
            <button className="c-button c-button--raised c-button--width" onClick={() => sendToValidation()}>CONTINUAR</button>
            <Link to="/" className="c-forget--password">Regresar</Link>
          </div>
          <div className="c-card__footer">
            <p>Todos los derechos reservados por GRUPO CORIL.</p>
          </div>
        </div>
      </div>
  )
}

export default ValidateInformation;
