import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import { db } from '../firebase/firebase-config';
import LOGO from '../images/logos/logo-identity.png';
import FONDO from '../images/fondo/fondo.png';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import '../styles/login.css';
import { addUserRegisterRequest, getUserDocumentType } from '../services/susuario';

const ValidateUser = ({ history }) => {
  const [isFetching, setFetching] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [datos, setDatos] = useState({
    typeDocument: '00',
    document : '',
    nombre  : '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNac: '',
    telefono: '',
    codigoPais: '51',
    correo: '',
    isForeinger: false,
    ubigeoDni: '',
    fechaEmisionDni: '',
    user_data_login: {},
    emptyForm: false,
    
  });
  const [userData, setUserData] = useState({});
  const [idUserDoc, setIdUserDoc] = useState('');

 

  const handleInputChange = (e) => {
    setDatos({
      ...datos,
      [e.target.name] : e.target.value
    })
  }

  const styleBackground = {
    backgroundImage:  `url(${FONDO})`,
    backgroundSize: 'cover'
  }

  const loadInitData = () => {
    getUserDocumentType();
  }

  const getUserData = () => {
    setFetching(true);
    db.collection("usuarios").where("user_rut", "==", localStorage.getItem('USER_RUT')).limit(1).get()
    .then(function(userSession) {
      userSession.forEach(function(snapSession) {
        setUserData(snapSession.data());
        setIdUserDoc(snapSession.id);
        setFetching(false);
      })
    })
    .catch(function (error) {
      console.error("Error obtener usuario: ", error);
      setFetching(false);
    });
  }

  const setPropiedadUltimoIntentoValidacion = async (intentoValidacion, id) => {
    await db
      .collection("usuarios")
      .doc(id)
      .update({
        ultimo_intento_validacion: intentoValidacion
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  }

  const registerUser = (e) => {
    let counterFails = 0;
    const typeDocument = datos.typeDocument;
    const document = datos.document.trim();
    const nombre = datos.nombre.trim();
    const apellidoPaterno = datos.apellidoPaterno.trim();
    const apellidoMaterno = datos.apellidoMaterno.trim();
    const fechaNac = datos.fechaNac;
    const codigoPais = datos.codigoPais.trim();
    const telefono = codigoPais+""+datos.telefono.trim();
    const correo = datos.correo.trim();
    const isForeinger = datos.isForeinger;
    const ubigeoDni = datos.ubigeoDni.trim();
    const fechaEmisionDni = datos.fechaEmisionDni;
    let fechaNacSeparada = fechaNac !== '' ? fechaNac.split('-') : '';
    let fechaNacFormateada = fechaNacSeparada.length !== 0 ? fechaNacSeparada[2] + '-' + fechaNacSeparada[1] + '-' + fechaNacSeparada[0] : '';
    let fechaEmisionDniSeparada = fechaEmisionDni !== '' ? fechaEmisionDni.split('-') : '';
    let fechaEmisionDniFormateada = fechaEmisionDniSeparada.length !== 0 ? fechaEmisionDniSeparada[2] + '-' + fechaEmisionDniSeparada[1] + '-' + fechaEmisionDniSeparada[0] : '';

    let typeDocumentDescription = typeDocument === '00' || typeDocument === '01' ? 'DNI' : 'CE';

    if (document.localeCompare(userData.user_rut.trim()) !== 0) { counterFails++;}
    if (nombre.localeCompare(userData.user_name.trim(), undefined, { sensitivity: 'base' }) !== 0) {counterFails++;}
    if (apellidoPaterno.localeCompare(userData.user_apellido_paterno.trim(), undefined, { sensitivity: 'base' }) !== 0) {counterFails++;}
    if (!userData.user_posee_un_apellido) {
      if (apellidoMaterno.localeCompare(userData.user_apellido_materno.trim(), undefined, { sensitivity: 'base' }) !== 0) {counterFails++;}
    }
    if (fechaNacFormateada.localeCompare(userData.user_birthday) !== 0) {counterFails++;}
    if (correo.localeCompare(userData.user_email.trim(), undefined, { sensitivity: 'base' }) !== 0) {counterFails++;}
    if (telefono.localeCompare(userData.user_phone.trim()) !== 0) {counterFails++;}
    if (typeDocumentDescription.localeCompare(userData.user_tipoDocumento) !== 0) {counterFails++;}
    if(typeDocumentDescription == 'DNI'){
      if (fechaEmisionDniFormateada.localeCompare(userData.user_dniFechaEmision) !== 0) {counterFails++;}
      if (ubigeoDni.localeCompare(userData.user_dniUbigeo.trim()) !== 0) {counterFails++;}
    }
    
    let intentoValidacion = {
      user_tipoDocumento: typeDocumentDescription,
      user_rut: document,
      user_name: nombre,
      user_apellido_paterno: apellidoPaterno,
      user_birthday: fechaNacFormateada,
      user_phone: telefono,
      user_email: correo,
      user_dniFechaEmision: fechaEmisionDniFormateada,
      user_dniUbigeo: ubigeoDni,
    };

    if (!userData.user_posee_un_apellido) {
      intentoValidacion["user_apellido_materno"] = apellidoMaterno;
    }

    setPropiedadUltimoIntentoValidacion(intentoValidacion, idUserDoc);

    if(counterFails==0){
      history.push({
        pathname: 'activar-cuenta',
        state: {
          user_data: history.location.state.user_data
        }
      });
    }else{
      Swal.fire('','Los datos ingresados no han podido ser validados. Por favor, comuniquese con su Funcionario de Negocios.','warning');
      return;
    }
  }
  
  const verificarDatos = (e) => {
    if(e.keyCode === 13){
       document.getElementById('guardar').click();
      }
  }

  const submitHandlerFormValidator = (event) => {
    event.target.className += " was-validated";
    const form = event.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    else{
      event.preventDefault();
      event.stopPropagation();
      console.log("fue validando");
      registerUser();
    }
    
  }

  const onChangeTypeDocument = (event) => {
    setDatos({
      ...datos,
      [event.target.name] : event.target.value
    });
  }

  const handleKeyNumeric = (e) => {
    var key = window.event ? e.which : e.keyCode;
    if (key < 48 || key > 57) {
      e.preventDefault();
    }
  }

  const AdditionalInputs = (e) => {
    if (datos.typeDocument === '04') {
      datos.ubigeoDni = "";
      datos.fechaEmisionDni = "";
      return null;
    }
    
    return (
      <div className="form-row">
        <div className="col c-input">
        <Form.Label>Ubigeo de Nacimiento</Form.Label>
          <Form.Control required type="text" placeholder="Ubigeo de Nacimiento" name="ubigeoDni" onChange={handleInputChange} onKeyUp={verificarDatos}/>
        </div>
        <div className="col c-input">
        <Form.Label>Fecha emisi&oacute;n DNI</Form.Label>
          <Form.Control required type="date" name="fechaEmisionDni" onChange={handleInputChange} onKeyUp={verificarDatos}/>
        </div>
      </div>
    );
  }

  const NameCheckBox = (props) => {
   /* if (props.show === '04') {
      return (
        <div>
          <div>
            <Form.Check type="checkbox" label="Si cuenta con un solo apellido, marcar esta opción." name="isForeinger" onChange={handleInputChange}/>
          </div>
        </div>
      );
    }*/
    return null;
  }

  useEffect(() => {
    getUserData();
  }, []);

  return(
    <Form className="needs-validation"
    onSubmit={submitHandlerFormValidator}
    noValidate >
      <div className="c-login" style={styleBackground}>
          { isFetching ? <Loading></Loading> : '' }
          <div className="c-login__card">
              <div className="c-card__title">
                <img src={LOGO} alt="Logo Identity" />
                <p>Validacion de Datos</p>
              </div>
              <div className="c-card__body validate">
                <div className="form-row">
                  <div className="col c-select" >
                    <Form.Label>Tipo de DNI</Form.Label>
                    <Form.Control as="select" name="typeDocument" onChange={onChangeTypeDocument}>
                      <option value='00'>DNI</option>
                      <option value='04'>C.E</option>
                    </Form.Control>
                  </div>
                  <div className="col c-input">
                      <Form.Label>N&uacute;mero de documento</Form.Label>
                      <Form.Control required type="text" placeholder="N&uacute;mero de documento" name="document" onChange={handleInputChange} onKeyUp={verificarDatos}/>
                     
                  </div>
                </div>
                
                <NameCheckBox show={datos.typeDocument}/>

                <div className="form-row">
                    <div className="col c-input">
                        <Form.Label>Nombres</Form.Label>
                        <Form.Control required type="text" placeholder="Nombres" name="nombre" onChange={handleInputChange} onKeyUp={verificarDatos}/>
                        
                    </div>
                    <div className="col c-input">
                        <Form.Label>Apellido paterno</Form.Label>
                        <Form.Control required type="text" placeholder="Apellido Paterno" name="apellidoPaterno" onChange={handleInputChange} onKeyUp={verificarDatos}/>
                        
                    </div>
                </div>
                <div className="form-row">
                    <div className="col c-input">
                      <Form.Label>Apellido materno</Form.Label>
                      <Form.Control required type="text" placeholder="Apellido Materno" name="apellidoMaterno" disabled={userData.user_posee_un_apellido}
                        onChange={handleInputChange} onKeyUp={verificarDatos}/>
                    </div>
                    <div className="col c-input">
                    <Form.Label>Fecha de nacimiento</Form.Label>
                        <Form.Control required type="date" name="fechaNac" onChange={handleInputChange} onKeyUp={verificarDatos}/>
                        
                    </div>
                </div>
                <Form.Row>
              <Form.Group className="col-md-6 c-input" controlId="validationCustomUsername">
                      <Form.Label>C&oacute;d. Pa&iacute;s + Tel&eacute;fono Celular</Form.Label>
                      <div className="input-group">
                          <div className="input-group-prepend">
                            <input onKeyPress={handleKeyNumeric}  required size="2" maxLength="2" className="form-control mr-1" value={datos.codigoPais} placeholder="Prefijo" name="codigoPais" onChange={handleInputChange} onKeyUp={verificarDatos} />
                          </div>
                        <Form.Control required type="text" placeholder="Tel&eacute;fono Celular" name="telefono" onChange={handleInputChange} onKeyUp={verificarDatos}/>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-md-6 c-input" controlId="validationCustom02">
                      <Form.Label>Correo Electr&oacute;nico</Form.Label>
                      <Form.Control type="text" required placeholder="Correo Electr&oacute;nico" name="correo" onChange={handleInputChange} onKeyUp={verificarDatos}/>
                    </Form.Group>
                    
                  </Form.Row>
                {AdditionalInputs()}
              </div>
              <div className="c-card__actions">
                  <button id="guardar" className="c-button c-button--raised c-button--width">VALIDAR</button>
                  <Link to="/" className="c-forget--password">Regresar</Link>
              </div>
              <div className="c-card__footer">
                  <p>Todos los derechos reservados por GRUPO CORIL.</p>
              </div>
          </div>
      </div>
    </Form>
  )
}

export default ValidateUser;
