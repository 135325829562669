
const rutaProduccion="/";
const rutaDesarrollo="/identity-test/";  // OJO: Importante incluir el "/" al final
//export const rutaBase = rutaDesarrollo;
export const rutaBase = rutaProduccion;

export const regexPhone = (str) => {
  return str.replace(/(?<=.).(?=[^@]*?.{2})/g, "*");
};

export const regexEmail = (str) => {
  return str.replace(/(?<=.).(?=[^@]*?.@)/g, "*");
};

export const mensajeErrorGenerico = "Ocurrio un error inesperado";
export const identificadorReintentosFallidosLogin = "reintentar en 30 min";
export const sistema = "Extranet Web";
