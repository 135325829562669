import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import LOGO_IDENTITY_HORIZONTAL from '../images/logos/logo-identity-horizontal.png';
import { Layout, Drawer, Header, Navigation, Content} from 'react-mdl';
import { Form } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import { apiCreatePin, apiLogout, apiRecoverPassword } from '../constants/api-url';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { db } from '../firebase/firebase-config';

const ChangePassword = ({history}) => {

    const [isFetching, setFetching] = useState(false);
    const [datos, setDatos] = useState({
        newPassword : '',
        confirmPassword  : ''
    });
    const SESSION_ID = localStorage.getItem('SESSION_ID');
    const VALUEAPIKEY = localStorage.getItem('VALUEAPIKEY_COMPANY');
    const USER_RUT = localStorage.getItem('USER_RUT');
    const USERNAME = localStorage.getItem('USER_NAME');
    const INSTITUTION_IMG = localStorage.getItem('INSTITUTION_IMG');
    const INSTITUTIONS = JSON.parse(localStorage.getItem('INSTITUTIONS'));
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name] : e.target.value
        })
    }

    const validatePassword = (password) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        return re.test(password);
    }

    const toggleNewPasswordVisiblity = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };

    const changePasswordUser = () => {
        const newPassword = datos.newPassword;
        const confirmPassword = datos.confirmPassword;
        if(newPassword === '' || newPassword === undefined || confirmPassword === '' || confirmPassword === undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
            return;
        }
        if(newPassword.length < 8 || confirmPassword.length < 8){
            Swal.fire('','Debe tener mas de 8 caracteres el campo de contrase\u00F1a','warning');
            return;
        }
        if(!validatePassword(newPassword) || !validatePassword(confirmPassword)){
            Swal.fire('','No cumple con el formato de contrase\u00F1a','warning');
            return;
        }
        if(newPassword !== confirmPassword){
            Swal.fire('','Las contrase\u00F1as no coinciden','error');
            return;
        }
        setFetching(true);
        axios({
            method: 'POST',
            url: apiRecoverPassword,
            data: {
                user_rut: USER_RUT,
                serial_number: confirmPassword
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            createPin(response.data.result.token)
        })
        .catch((error) => {

        });
    }

    const createPin = (token) => {
        axios({
            method: 'POST',
            url: apiCreatePin,
            data: {
                user_rut: USER_RUT,
                serial_number: USER_RUT,
                token: token,
                pin: datos.confirmPassword
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            setFetching(false);
            localStorage.setItem('USER_PIN', datos.confirmPassword);
            db.collection("usuarios").where("user_rut", "==", USER_RUT).limit(1).get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(snapSession) {
                    snapSession.ref.update({pin : datos.confirmPassword })
                    .catch(function (error) {
                        console.error("Error actualizar contraseña", error);
                    });
                })
            })
            .catch(function (error) {
                console.error("Error obtener usuario: ", error);
            });
            setDatos({
                newPassword : '',
                confirmPassword  : ''
            })
            document.getElementById('newPassword').value = "";
            document.getElementById('confirmPassword').value = "";
            Swal.fire('','Su contrase\u00F1a fue cambiada con \u00E9xito','success');
        })
        .catch((error) => {
            setFetching(false);
        });
    }

    const logoutUser = () => {
        axios({
            method: 'POST',
            url: apiLogout,
            data: {
                session_id: SESSION_ID
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            history.push('/');
        })
        .catch((error) => {
            
        });
    }

    return(
        <Layout fixedHeader fixedDrawer className="c-layout">
            <Header className="c-header">
                <div className="c-header__title">
                    <img src={LOGO_IDENTITY_HORIZONTAL} alt="Logo Identity" />
                </div>
            </Header>
            <Drawer className="c-drawer">
                <div className="c-drawer__image">
                    <div className="c-image">
                        <img src={INSTITUTION_IMG} alt="Logo Identity" />
                    </div>
                    <h2>{USERNAME}</h2>
                </div>
                <Navigation>
                    <Link to={{ pathname: 'empresas', state: { institutions: INSTITUTIONS }}} className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">home</i>
                        </div>
                        <p>Mis empresas</p>
                    </Link>
                    <NavLink to="firmas" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">edit</i>
                        </div>
                        <p>Doc. por firmar</p>
                    </NavLink>
                    <NavLink to="proceso-firmas" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">rotate_left</i>
                        </div>
                        <p>Doc. en proceso de firmas</p>
                    </NavLink>
                    <NavLink to="firmados" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">done</i>
                        </div>
                        <p>Doc. firmados</p>
                    </NavLink>
                    <NavLink to="observados" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">info</i>
                        </div>
                        <p>Doc. observados</p>
                    </NavLink>
                    <NavLink to="cambiar-contrasena" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">vpn_key</i>
                        </div>
                        <p>Cambio de contrase&ntilde;a</p>
                    </NavLink>
                    <a href="#" className="c-nav__item" onClick={() => logoutUser()}>
                        <div className="c-nav__icon">
                            <i className="material-icons">keyboard_return</i>
                        </div>
                        <p>Cerrar sesi&oacute;n</p>
                    </a>
                </Navigation>
            </Drawer>
            <Content>
                { isFetching ? <Loading></Loading> : '' }
                <div className="c-page__title">
                    <h2>Cambio de Contrase&ntilde;a</h2>
                </div>
                <div className="page-content">
                    <div className="c-card--container">
                        <div className="c-content__password">
                        <p>Por favor, tener en cuenta lo siguiente para su nueva contrase&ntilde;a:</p>
                        <ul>
                            <li>La longitud mínima es de 8 caracteres y la máxima de 12.</li>
                            <li>Debe contener al menos un número (0 - 9), una letra mayúscula (A - Z), una letra minúscula (a - z).</li>
                            <li>Debe contener al menos un carácter especial (por ejemplo, !, #, $, *, ?).</li>
                        </ul>
                            <div className="c-input" style={{ position: 'relative' }}>
                                <Form.Control type={newPasswordShown ? "text" : "password"} id="newPassword" placeholder="Nueva contraseña" name="newPassword" onChange={handleInputChange}/>
                                <i onClick={toggleNewPasswordVisiblity}
                                ><FontAwesomeIcon icon={ newPasswordShown ? faEye : faEyeSlash } /></i>
                            </div>
                            <div className="c-input" style={{ position: 'relative' }}>
                                <Form.Control type={confirmPasswordShown ? "text" : "password"} id="confirmPassword" placeholder="Confirmar nueva contraseña" name="confirmPassword" onChange={handleInputChange}/>
                                <i onClick={toggleConfirmPasswordVisiblity}
                                ><FontAwesomeIcon icon={ confirmPasswordShown ? faEye : faEyeSlash } /></i>
                            </div>
                            <div className="c-actions">
                                <button className="c-button c-button--raised" onClick={() => changePasswordUser()}>Cambiar Contrase&ntilde;a</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}
export default ChangePassword;
