import firebase from 'firebase/app';
import 'firebase/firestore';

// Desarrollo
var firebaseConfig = {
    apiKey: "AIzaSyDfTtAd36DUJauJdUnZxW9PTU8pI-E_xhA",
    authDomain: "coril-identity-des.firebaseapp.com",
    projectId: "coril-identity-des",
    // databaseURL: ?
    databaseURL: "https://coril-identity-des.firebaseio.com",
    storageBucket: "coril-identity-des.appspot.com",
    messagingSenderId: "503824602528",
    appId: "1:503824602528:web:7c5b8f1cdbe2ade4bac0c5",
    measurementId: "G-WR4PLLKDZK"
  };


// Produccion
const firebaseConfigProd = {
    apiKey: "AIzaSyDfPR7cjceF62h4J6fUZL2QoMWb4XBnKVQ",
    authDomain: "coril-identity-e7996.firebaseapp.com",
    databaseURL: "https://coril-identity-e7996.firebaseio.com",
    projectId: "coril-identity-e7996",
    storageBucket: "coril-identity-e7996.appspot.com",
    messagingSenderId: "450521576249",
    appId: "1:450521576249:web:97bdc103fae66e0cdb6ee8",
    measurementId: "G-JCX6XNEVNL"
};
// Initialize Firebase
//firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfigProd);

const db = firebase.firestore();

export{
    db,
    firebase
};