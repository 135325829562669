import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => {
    return (
        <div className="c-loading">
            <div className="c-loading__content">
                <div className="c-loading__spinner">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                <p>Cargando...</p>
            </div>
        </div>
    )
}

export default Loading;