import React from "react";
import Login from "./components/Login";
import GeneratePassword from "./components/GeneratePassword";
import RestorePassword from "./components/RestorePassword";
import Active from "./components/Active";
import Company from "./components/Company";
import DetailDocument from "./components/DetailDocument";
import Sign from "./components/Sign";
import Signed from "./components/Signed";
import Process from "./components/Process";
import ChangePassword from "./components/ChangePassword";
import ValidateUser from "./components/ValidateUser";
import ValidateInformation from "./components/ValidateInformation";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import Observados from "./components/Observados";
import { rutaBase } from "./constants/utils";

const App = () => {
  return (
    <BrowserRouter>
      <Route exact path="/">
        <Redirect to={rutaBase}/>
      </Route>
      <Route path={`${rutaBase}`} exact component={Login} />
      <Route path={`${rutaBase}generar-contrasena`} component={GeneratePassword} />
      <Route path={`${rutaBase}restaurar-contrasena`} component={RestorePassword} />
      <Route path={`${rutaBase}activar-cuenta`} component={Active} />
      <Route path={`${rutaBase}empresas`} component={Company} />
      <Route path={`${rutaBase}firmas`} component={Sign} />
      <Route path={`${rutaBase}firmados`} component={Signed} />
      <Route path={`${rutaBase}proceso-firmas`} component={Process} />
      <Route path={`${rutaBase}observados`} component={Observados} />
      <Route path={`${rutaBase}detalle-documento`} component={DetailDocument} />
      <Route path={`${rutaBase}cambiar-contrasena`} component={ChangePassword} />
      <Route path={`${rutaBase}validacion-usuario`} component={ValidateUser} />
      <Route path={`${rutaBase}informacion-validacion`} component={ValidateInformation} />
    </BrowserRouter>
  );
};

export default App;
