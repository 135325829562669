import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import PDFViewer from 'pdf-viewer-reactjs'
import { PDFObject } from 'react-pdfobject';
import Iframe from 'react-iframe';
import LOGO_IDENTITY_HORIZONTAL from '../images/logos/logo-identity-horizontal.png';
import { Layout, Drawer, Header, Navigation, Content} from 'react-mdl';
import { Button, Modal, Form} from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import { 
    apiLogout, apiSign, apiSignReject,
    apiGenerarClaveDigitalByPhone,
    tituloMensajeClaveDigitalPhone, applicationIdClaveDigitalPhone,
    messageIdClaveDigitalPhone, reskey, apiValidarClaveDigitalPhone, apiEmailServer
} from '../constants/api-url';
import { RadioGroup , Radio } from 'react-mdl';
import { db } from '../firebase/firebase-config';
import { regexPhone, regexEmail, mensajeErrorGenerico, sistema } from '../constants/utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const DetailDocument = ({history}) => {

    const [isFetching, setFetching] = useState(false);
    const [typeState, setTypeState] = useState("");
    const [detailDocument, setDetailDocument] = useState({});
    const [signerDocument, setSignerDocument] = useState([]);
    const [canSign, setCanSign] = useState([]);
    const [modalShowDoc, setModalShowDoc] = useState(false);
    const [modalShowObs, setModalShowObs] = useState(false);
    const [modalShowSign, setModalShowSign] = useState(false);

    const SESSION_ID = localStorage.getItem('SESSION_ID');
    const TIPO_FIRMA = localStorage.getItem('TIPO_FIRMA');
    const VALUEAPIKEY = localStorage.getItem('VALUEAPIKEY_COMPANY');
    const USERNAME = localStorage.getItem('USER_NAME');
    const USER_RUT = localStorage.getItem('USER_RUT');
    const USER_PHONE = localStorage.getItem('USER_PHONE');
    const USER_PIN = localStorage.getItem('USER_PIN');
    const INSTITUTION = localStorage.getItem('INSTITUTION');
    const INSTITUTION_IMG = localStorage.getItem('INSTITUTION_IMG');
    const INSTITUTIONS = JSON.parse(localStorage.getItem('INSTITUTIONS'));

    let type_tipo_firma = "";
    let role_user = "";

    const Accion = {
        FIRMAR: "Firmar",
        VISAR: "Visar",
        OBSERVAR: "Observar",
    };

    useEffect(()=> {
        console.log(history);
        setDetailDocument(history.location.state.detailDocument.result);
        setSignerDocument(history.location.state.detailDocument.result.signers);
        setTypeState(history.location.state.typeState)
        setCanSign(history.location.state.detailDocument.result.can_sign_info);
    }, [])

    canSign.forEach(signer => {
        if (signer.institution == INSTITUTION) {
            type_tipo_firma =  signer.type
            role_user = signer.role;
        }
    });

    const logoutUser = () => {
        axios({
            method: 'POST',
            url: apiLogout,
            data: {
                session_id: SESSION_ID
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            history.push('/');
        })
        .catch((error) => {

        });
    }

    const MyModalDocument = (props) => {
    
        return (
            <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Visualizando documento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <iframe sandbox="allow-same-origin allow-scripts allow-forms" src={props.srcIframe} width="100%" height="480" /> */}
                    <PDFViewer
                        document={{
                            url: props.srcIframe,
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="c-button c-button--raised" onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const guardarHistorialDocumento = (accion) => {
        let firmantesCoril = [];
        let firma = {
            accion: accion,
            nombre_usuario: USERNAME,
            user_rut: USER_RUT,
            fecha_hora: new Date(),
            rol: role_user,
            sistema_utilizado: sistema
        }
        let historialDocumento = {
            nombre_documento: detailDocument.name,
            cod_documento: detailDocument.code,
            institucion: detailDocument.creator?.institution,
            cod_plantilla: detailDocument.type_code,
            nro_firmantes: signerDocument.length,
        }
        db.collection("historial_documentos").doc(detailDocument.code).get()
        .then(function (doc) {
            if (doc.data() != null) {
                firmantesCoril = doc.data().firmantes_coril;
                firmantesCoril.push(firma);
                historialDocumento['nro_firmas_coril'] = firmantesCoril.length;
                historialDocumento['firmantes_coril'] = firmantesCoril;
                doc.ref.update(historialDocumento);
            } else {
                firmantesCoril.push(firma);
                historialDocumento['nro_firmas_coril'] = firmantesCoril.length;
                historialDocumento['firmantes_coril'] = firmantesCoril;
                db.collection("historial_documentos").doc(detailDocument.code).set(historialDocumento);
            }
        })
    }
    
    const MyModalSign = (props) => {
    
        const EnviarClaveDigital = {
            PHONE: "phone",
            EMAIL: "email",
        };
        const [isFetching, setFetching] = useState(false);
        const [isPinId, setPinId] = useState("");
        const [datos, setDatos] = useState({
            password : '',
            claveDigital  : ''
        });
        const [passwordShown, setPasswordShown] = useState(false);
        const [dataPhone, setDataPhone] = useState("");
        const [dataEmail, setDataEmail] = useState("");
        const [dataTipoDocumento, setDataTipoDocumento] = useState("");
        const [dataIdUsuario, setDataIdUsuario] = useState("");
        const [radioButtonEnviarClaveDigital, setRadioButtonEnviarClaveDigital] = useState(EnviarClaveDigital.PHONE);

        let pinValidadoAnalista = false;

        const togglePasswordVisiblity = () => {
            setPasswordShown(!passwordShown);
        };
    
        const handleInputChange = (e) => {
            setDatos({
                ...datos,
                [e.target.name] : e.target.value
            })
        }

        const handleRadioButtonEnviarClaveDigitalChange = (e) => {
            setRadioButtonEnviarClaveDigital(e.target.value)
        }

        const getUser = () => {
            setFetching(true);
            db.collection("usuarios").where("user_rut", "==", USER_RUT).get()
            .then(function(querySnapshot) {
                if(querySnapshot.empty){
                    setFetching(false);
                    Swal.fire('','DNI no encontrado','error');
                }else{
                    querySnapshot.forEach(function(dataUser) {
                        setFetching(false);
                        setDataPhone(dataUser.data().user_phone);
                        setDataEmail(dataUser.data().user_email);
                        setDataTipoDocumento(dataUser.data().user_tipoDocumento);
                        setDataIdUsuario(dataUser.id);
                    });
                }
            });
        }

        const generatePinByPhone = () => {
            setFetching(true);
            axios({
                method: 'POST',
                url: apiGenerarClaveDigitalByPhone,
                data: {
                    applicationId: applicationIdClaveDigitalPhone,
                    messageId: messageIdClaveDigitalPhone,
                    from : tituloMensajeClaveDigitalPhone,
                    to: USER_PHONE,
                    placeholders: {}
                },
                headers: {
                    'Authorization': reskey,
                    'Content-Type':  'application/json',
                }
            })
            .then(function (response) {
                setFetching(false);
                setPinId(response.data.pinId);
                Swal.fire('','Clave digital enviada, por favor espere ...','success');
            })
            .catch(() => {
                setModalShowSign(false);
                Swal.fire('', mensajeErrorGenerico, 'error');
                setFetching(false);
            });
        }

        const generateClaveDigitalByEmail = () => {
            setFetching(true);
            axios({
                method: 'POST',
                url: `${apiEmailServer}enviarClaveDigitalEmail`,
                data: {
                    user_tipoDocumento: dataTipoDocumento,
                    user_rut: USER_RUT
                },
            })
            .then(function (response) {
                setFetching(false);
                Swal.fire('','Clave digital enviada a su correo','success');
            })
            .catch(() => {
                setModalShowSign(false);
                Swal.fire('', mensajeErrorGenerico, 'error');
                setFetching(false);
            });
        }
    
        const signDocument = (text) => {
            const password = datos.password;
            if(password == '' || password == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
                return;
            }
            if(password != USER_PIN){
                Swal.fire('','Contrase\u00F1a inv\u00E1lida','error');
                return;
            }
            setFetching(true);
            axios({
                method: 'POST',
                url: apiSign,
                data: {
                    user_rut: USER_RUT,
                    user_pin: password,
                    user_role: role_user,
                    user_institution: INSTITUTION,
                    code: props.dataDocument.code,
                    session_id: SESSION_ID
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY' : VALUEAPIKEY
                }
            })
            .then(function (response) {
                setFetching(false);
                setModalShowSign(false);
                if (pinValidadoAnalista || radioButtonEnviarClaveDigital === EnviarClaveDigital.EMAIL) {
                    let datoActualizar = pinValidadoAnalista ? { pin_generado_analista: "" } : { user_clavedigital: "" };
                    db.collection("usuarios").doc(dataIdUsuario)
                    .update(datoActualizar)
                    .catch(function (error) {
                        console.error("Error updating document: ", error);
                    });
                }
                (type_tipo_firma  == 0) ? guardarHistorialDocumento(Accion.FIRMAR) : guardarHistorialDocumento(Accion.VISAR);
                Swal.fire('','Documento '+text+' con \u00E9xito','success');
                history.push('firmas');
            })
            .catch((error) => {
                setFetching(false);
                setModalShowSign(false);
                if (401 === error.response.status) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Sesi\u00F3n expirada',
                        text: 'Su sesi\u00F3n ha caducado. Ser\u00E1 redirigido a la p\u00E1gina de inicio de sesi\u00F3n para logearse nuevamente.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        confirmButtonText: `OK`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            logoutUser()
                        }
                    })
                } else {
                    Swal.fire('', error.response.data.message, 'error');
                }
            });
        }

        const servicioValidarClaveDigitalPhone = (pin, claveDigital) => {
            axios({
                method: 'POST',
                url: apiValidarClaveDigitalPhone(pin),
                data: {
                    pin: claveDigital
                },
                headers: {
                    'Authorization': reskey,
                    'Content-Type': 'application/json',
                }
            })
            .then(function (response) {
                if (response.data.verified) {
                    (type_tipo_firma  == 0) ? signDocument("firmado") : signDocument("visado");
                } else {
                    if (pinValidadoAnalista) {
                        setFetching(false);
                        setModalShowSign(false);
                        Swal.fire('','Clave Digital inv\u00E1lido','error');
                    } else {
                        verifyPinGeneradoAnalista(claveDigital);
                    }
                }
            })
            .catch(() => {
                setModalShowSign(false);
                Swal.fire('', mensajeErrorGenerico, 'error');
                setFetching(false);
            });
        }

        const verifyPinGeneradoAnalista = (claveDigital) => {
            db.collection("usuarios").where("user_rut", "==", USER_RUT).get()
                .then(function(querySnapshot) {
                    if (querySnapshot.empty) {
                        setFetching(false);
                        Swal.fire('','DNI no encontrado','error');
                    } else {
                        querySnapshot.forEach(function(dataUser) {
                            pinValidadoAnalista = true;
                            if (dataUser.data().pin_generado_analista != "") {
                                servicioValidarClaveDigitalPhone(dataUser.data().pin_generado_analista, claveDigital);
                            } else {
                                setModalShowSign(false);
                                Swal.fire('','Clave Digital inv\u00E1lido','error');
                            }
                        });
                    }
                });
        }
    
        const verifyPhone = () => {
            const password = datos.password;
            const claveDigital = datos.claveDigital;
            if(password == '' || password == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
                return;
            }
            if(password != USER_PIN){
                Swal.fire('','Contrase\u00F1a inv\u00E1lida','error');
                return;
            }
            if(claveDigital == '' || claveDigital == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de Clave Digital','warning');
                return;
            }
            setFetching(true);
            if (isPinId != "") {
                servicioValidarClaveDigitalPhone(isPinId, claveDigital);
            } else {
                verifyPinGeneradoAnalista(claveDigital);
            }
        }

        const verifyEmail = () => {
            const password = datos.password;
            const claveDigital = datos.claveDigital;
            if(password == '' || password == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
                return;
            }
            if(password != USER_PIN){
                Swal.fire('','Contrase\u00F1a inv\u00E1lida','error');
                return;
            }
            if(claveDigital == '' || claveDigital == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de Clave Digital','warning');
                return;
            }
            setFetching(true);
            db.collection("usuarios")
            .where("user_rut", "==", USER_RUT)
            .where("user_clavedigital", "==", claveDigital)
            .get()
            .then(function(querySnapshot) {
                if(querySnapshot.empty){
                    setFetching(false);
                    setModalShowSign(false);
                    Swal.fire('','Clave digital inv\u00E1lida','error');
                }else{
                    setFetching(false);
                    (type_tipo_firma  == 0) ? signDocument("firmado") : signDocument("visado");
                }
            });
        }

        useEffect(()=> {
            getUser();
        }, [])
    
        return (
            <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                { isFetching ? <Loading></Loading> : '' }
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {
                            (type_tipo_firma  == 0) ? 'Firmar documento' : 'Visar documento'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="c-input" >
                        <label className="c-label">Contraseña</label>
                        <div style={{ position: 'relative' }}>
                            <Form.Control type={passwordShown ? "text" : "password"} placeholder="Contraseña" name="password" onChange={handleInputChange}/>
                            <i onClick={togglePasswordVisiblity}><FontAwesomeIcon icon={ passwordShown ? faEye : faEyeSlash } /></i>
                        </div>
                    </div>
                    {
                        (type_tipo_firma  == 0)
                        ?
                            (TIPO_FIRMA == 1) ? '' : 
                            <div className="c-input">
                                <label className="c-label">Clave Digital</label>
                                <div className="c-digital">
                                    <p>Enviar Clave Digital a:</p>
                                    <RadioGroup container="ul" childContainer="li" name="generar_clave" 
                                        value={radioButtonEnviarClaveDigital} onChange={handleRadioButtonEnviarClaveDigitalChange}>
                                        <Radio name={EnviarClaveDigital.EMAIL} value={EnviarClaveDigital.EMAIL}>{regexEmail(dataEmail)}</Radio>
                                        <Radio name={EnviarClaveDigital.PHONE} value={EnviarClaveDigital.PHONE}>{regexPhone(dataPhone)}</Radio>
                                    </RadioGroup>
                                </div>
                                <div className="c-flexcenter">
                                    <Form.Control type="text" placeholder="Clave Digital" name="claveDigital" onChange={handleInputChange}/>
                                    <Button className="c-button c-button--raised" onClick={() => (radioButtonEnviarClaveDigital === EnviarClaveDigital.PHONE) 
                                        ? generatePinByPhone() : generateClaveDigitalByEmail()} disabled={isFetching ? true : false} >GENERAR CLAVE</Button>
                                </div>
                            </div>
                        : ''
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="c-button c-button--default" onClick={props.onHide}>Cancelar</Button>
                    {
                        (type_tipo_firma  == 0)
                        ?
                            (TIPO_FIRMA == 1) 
                            ? <Button className="c-button c-button--raised c-secundary" onClick={() => signDocument("firmado")}>Firmar</Button>
                            : <Button className="c-button c-button--raised c-secundary" onClick={() => (radioButtonEnviarClaveDigital === EnviarClaveDigital.PHONE) 
                                ? verifyPhone() : verifyEmail()}>Firmar</Button>
                        :   <Button className="c-button c-button--raised c-secundary" onClick={() => signDocument("visado")}>Visar</Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
    
    const MyModalObservation = (props) => {
    
        const [isFetching, setFetching] = useState(false);
        const [datos, setDatos] = useState({
            password : '',
            comment  : ''
        });
        const [passwordShown, setPasswordShown] = useState(false);

        const togglePasswordVisiblity = () => {
            setPasswordShown(!passwordShown);
        };

        const handleInputChange = (e) => {
            setDatos({
                ...datos,
                [e.target.name] : e.target.value
            })
        }
    
        const observeDocument = () => {
            const password = datos.password;
            const comment = datos.comment;
            if(password == '' || password == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
                return;
            }
            if(password != USER_PIN){
                Swal.fire('','Contrase\u00F1a inv\u00E1lida','error');
                return;
            }
            if(comment == '' || comment == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de comentario','warning');
                return;
            }
            setFetching(true);
            axios({
                method: 'POST',
                url: apiSignReject,
                data: {
                    user_rut: USER_RUT,
                    user_pin: password,
                    user_role: role_user,
                    user_institution: INSTITUTION,
                    code: props.dataDocument.code,
                    comment: comment,
                    session_id: SESSION_ID
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY' : VALUEAPIKEY
                }
            })
            .then(function (response) {
                setFetching(false);
                setModalShowObs(false);
                guardarHistorialDocumento(Accion.OBSERVAR);
                Swal.fire('','Documento rechazado con \u00E9xito','success');
                history.push('firmas');
            })
            .catch((error) => {
                setFetching(false);
                if (401 === error.response.status) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Sesi\u00F3n expirada',
                        text: 'Su sesi\u00F3n ha caducado. Ser\u00E1 redirigido a la p\u00E1gina de inicio de sesi\u00F3n para logearse nuevamente.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        confirmButtonText: `OK`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            logoutUser()
                        }
                    })
                }
            });
        }
    
        return (
            <Modal show={props.show} onHide={props.onHide} size="sm" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                { isFetching ? <Loading></Loading> : '' }
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Observar documento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="c-input" >
                        <label className="c-label">Contraseña</label>
                        <div style={{ position: 'relative' }}>
                            <Form.Control type={passwordShown ? "text" : "password"} placeholder="Contraseña" name="password" onChange={handleInputChange}/>
                            <i onClick={togglePasswordVisiblity}><FontAwesomeIcon icon={ passwordShown ? faEye : faEyeSlash } /></i>
                        </div>
                    </div>
                    <div className="c-input">
                        <label className="c-label">Comentario</label>
                        <Form.Control type="text" as="textarea" rows="3" placeholder="Comentario" name="comment" onChange={handleInputChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="c-button c-button--default" onClick={props.onHide}>Cancelar</Button>
                    <Button className="c-button c-button--raised c-secundary" onClick={() => observeDocument()}>Confirmar</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const setAlertNotSign = () =>{
        Swal.fire('','No esta habilitado para poder firmar este documento','warning');
    }

    const setAlertNotObserve = () =>{
        Swal.fire('','No esta habilitado para poder observar este documento','warning');
    }

    const changeFormatDate = (dateFormat) => {
        const date = dateFormat;
        const newDate = dateFormat.split(" ")[0];
        const newHour = dateFormat.split(" ")[1];
        var formatDate = newDate.split("/");
        var newDateFormat = formatDate[2]+'/'+formatDate[1] +'/'+formatDate[0] +' '+ newHour
        return newDateFormat;
    }

    const donwloadPdf = (urlPdf) => {
        setFetching(true)
        axios({
            url: urlPdf.file_preview,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', urlPdf.name+'.pdf');
            document.body.appendChild(link);
            link.click();
            setFetching(false)
        });
    }

    return(
        <Layout fixedHeader fixedDrawer className="c-layout">
            <Header className="c-header">
                <div className="c-header__title">
                    <img src={LOGO_IDENTITY_HORIZONTAL} alt="Logo Identity" />
                </div>
            </Header>
            <Drawer className="c-drawer">
                <div className="c-drawer__image">
                    <div className="c-image">
                        <img src={INSTITUTION_IMG} alt="Logo Identity" />
                    </div>
                    <h2>{USERNAME}</h2>
                </div>
                <Navigation>
                    <Link to={{ pathname: 'empresas', state: { institutions: INSTITUTIONS }}} className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">home</i>
                        </div>
                        <p>Mis empresas</p>
                    </Link>
                    <NavLink to="firmas" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">edit</i>
                        </div>
                        <p>Doc. por firmar</p>
                    </NavLink>
                    <NavLink to="proceso-firmas" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">rotate_left</i>
                        </div>
                        <p>Doc. en proceso de firmas</p>
                    </NavLink>
                    <NavLink to="firmados" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">done</i>
                        </div>
                        <p>Doc. firmados</p>
                    </NavLink>
                    <NavLink to="observados" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">info</i>
                        </div>
                        <p>Doc. observados</p>
                    </NavLink>
                    <NavLink to="cambiar-contrasena" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">vpn_key</i>
                        </div>
                        <p>Cambio de contrase&ntilde;a</p>
                    </NavLink>
                    <a href="#" className="c-nav__item" onClick={() => logoutUser()}>
                        <div className="c-nav__icon">
                            <i className="material-icons">keyboard_return</i>
                        </div>
                        <p>Cerrar sesi&oacute;n</p>
                    </a>
                </Navigation>
            </Drawer>
            <Content>
                { isFetching ? <Loading></Loading> : null }
                <div className="c-page__title">
                    <p>Detalle del documento</p>
                </div>
                <div className="page-content">
                <div className="c-card--container">
                        <div className="c-card__detail">
                            <div className="c-detail__content">
                                <h2>{detailDocument.name}</h2>
                                <p>Estado: {
                                    (() => {
                                        switch( detailDocument.state ){
                                            case '0' : return 'Pendiente de Firma';
                                            case '1' : return 'En Proceso de Firma';
                                            case '2' : return 'Aprobado';
                                            case '3' : return 'Observado';
                                        }
                                    })()
                                }</p>
                                <p>C&oacute;digo: {detailDocument.code}</p>
                                <p>Fecha: {( detailDocument.date === '' || detailDocument.date === null || detailDocument.date === undefined ) ? '' : changeFormatDate(detailDocument.date)}</p>
                                <div className="c-detail__rutas">
                                    <h3>Ruta Vistos/Firmas</h3>
                                    {signerDocument.map((data,index)=>(
                                        <div key={index} className="c-rutas__item">
                                            <p>{
                                                (data.name == '') ? data.role : data.name
                                            }</p>
                                            <div className="c-rutas__item--state">
                                                <p>
                                                    {(() => {
                                                        switch( data.state ){
                                                            case 0 : return 'Pendiente';
                                                            case 1 : return 'Firmado';
                                                            case 2 : return 'Visado';
                                                            case 3 : return 'Observado';
                                                            case 4 : return 'Compartido';
                                                        }
                                                    })()}
                                                </p>
                                                <p>{( data.date === '' || data.date === null || data.date === undefined ) ? '' : changeFormatDate(data.date)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {typeState == 3 && detailDocument.comments.length > 0 ? (
                                <div className="c-detail__observaciones">
                                    <h3>Observaciones</h3>
                                    {detailDocument.comments.map((comentario,index) => (
                                        <div key={index} className="c-observaciones__item">
                                            <p> {comentario.comment} </p>
                                            <div className="c-observaciones__item--user_name">
                                                <p> {comentario.user_name} </p>
                                                <p>{( comentario.date === '' 
                                                    || comentario.date === null 
                                                    || comentario.date === undefined )
                                                    ? '' 
                                                    : changeFormatDate(comentario.date)}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                ) : null}
                            </div>
                            <div className="c-detail__actions">
                                {(() => {
                                    switch( typeState ){
                                        case 0 : return(
                                            <div className="c-actions__button">
                                                <a href={detailDocument.file_preview} target="_blank" className="c-button c-button--raised">Ver documento</a>
                                                {
                                                    (detailDocument.can_sign === 1)
                                                    ?<Button className="c-button c-button--raised c-secundary" onClick={() => setModalShowSign(true)}>{(type_tipo_firma == 0) ? "Firmar" : "Visar"}</Button>
                                                    :<Button className="c-button c-button--raised c-secundary" onClick={() => setAlertNotSign()}>{(type_tipo_firma == 0) ? "Firmar" : "Visar"}</Button>
                                                }
                                                {
                                                    (detailDocument.can_sign === 1)
                                                    ?<Button className="c-button c-button--raised c-pastel" onClick={() => setModalShowObs(true)}>Observar</Button>
                                                    :<Button className="c-button c-button--raised c-pastel" onClick={() => setAlertNotObserve()}>Observar</Button>
                                                }
                                            </div>
                                        );
                                        case 1 : return(
                                            <div className="c-actions__button">
                                                <a href={detailDocument.file_preview} target="_blank" className="c-button c-button--raised">Ver documento</a>
                                                {/* <Button className="c-button c-button--raised" onClick={() => setModalShowDoc(true)}>Ver documento</Button> */}
                                            </div>
                                        );
                                        case 2 : return(
                                            <div className="c-actions__button">
                                                <a href={detailDocument.file_preview} target="_blank" className="c-button c-button--raised">Ver documento</a>
                                                <button onClick={() => donwloadPdf(detailDocument)} className="c-button c-button--raised c-secundary">Descargar</button>
                                            </div>
                                        );
                                        case 3 : return(
                                            <div className="c-actions__button">
                                                <a href={detailDocument.file_preview} target="_blank" className="c-button c-button--raised">Ver documento</a>
                                            </div>
                                        );
                                    }
                                })()}
                                <div className="c-actions__code">
                                    <img src={'data:image/jpeg;base64,' + detailDocument.qr} alt="code qr" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MyModalDocument show={modalShowDoc} onHide={() => setModalShowDoc(false)} srcIframe={detailDocument.file_preview} />
                <MyModalSign show={modalShowSign} onHide={() => setModalShowSign(false)} dataDocument={detailDocument}/>
                <MyModalObservation show={modalShowObs} onHide={() => setModalShowObs(false)} dataDocument={detailDocument}/>
            </Content>
        </Layout>
    )
}

export default DetailDocument;