const urlBase = "http://localhost:9090/api/identity";

// ************** USUARIOS ************** //
export const urlUsuario = `${urlBase}/usuario`;

//APIS-DEC5
const dec5Host = "5cl"; // 5cap -> DESA/TEST, 5cl -> PROD
export const apiLogin = `https://${dec5Host}.dec.com.pe/api/v1/auth/login`;
export const apiCreatePin = `https://${dec5Host}.dec.com.pe/api/v1/auth/create_pin`;
export const apiRecoverPassword = `https://${dec5Host}.dec.com.pe/api/v1/auth/recover_password`;
export const apiLogout = `https://${dec5Host}.dec.com.pe/api/v1/auth/logout`;
export const apiSign = `https://${dec5Host}.dec.com.pe/api/v1/sign`;
export const apiSignReject = `https://${dec5Host}.dec.com.pe/api/v1/sign/reject`;
export const apiSearch = `https://${dec5Host}.dec.com.pe/api/v1/search/state`;
export const apiDocuments = `https://${dec5Host}.dec.com.pe/api/v1/documents`;

//API SMS
export const apiGenerarClaveDigitalByPhone =
  "https://9npyr.api.infobip.com/2fa/2/pin?ncNeeded=true";
export const reskey =
  "App 8c92d1ac9b27c3f3cb0089d81e5bea3d-24643b6d-6f84-4971-afdd-ea8f9de62772";
export const tituloMensajeClaveDigitalPhone = "Coril_Identity";
export const applicationIdClaveDigitalPhone = "342552A181526F34C8F61F36DF4920B9";
export const messageIdClaveDigitalPhone = "E9235174395D240B68DF603E1C7745AC";
export const apiValidarClaveDigitalPhone = (isPinId) => {
  return "https://9npyr.api.infobip.com/2fa/2/pin/" + isPinId + "/verify";
};

export const apiEmailServer = "https://apps.grupocoril.com/identity-spring-boot-prod/";
