import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import './styles/style.css';

const root = document.getElementById('root');

ReactDOM.render(<App />,root);
